<template>
  <div class="page">
    <form @submit="submitForm">
      <div class="field">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            autocomplete="off"
            label="Senha"
            placeholder="Insira sua nova senha"
            outlined
            v-model="senha"
            type="password"
            name="senha"
            @click="(e) => selecionarcampo(e)"
            :error-messages="erros.senha"
          ></v-text-field>
        </v-col>
      </div>

      <div class="field">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            autocomplete="off"
            label="Confirmar Senha"
            placeholder="Confirme sua senha"
            outlined
            v-model="confirmarsenha"
            type="password"
            name="confirmarsenha"
            @click="(e) => selecionarcampo(e)"
            :error-messages="erros.confirmarsenha"
          ></v-text-field>
        </v-col>
      </div>
      
      <div class="field">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <button type="submit">Redefinir</button>
        </v-col>
      </div>

      <div class="field">
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <p style="text-align: start;font-size: 12px;margin-top: 1em">Lembrou sua senha? <a @click="$router.push('/login')">Click aqui.</a></p>
        </v-col>
      </div>
    </form>
  </div>
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import AuthServico from '@/services/autenticacao.service.js'
export default {
  data(){
    return{
      senha: null,
      confirmarsenha: null,
      erros: {
        senha: '',
        confirmarsenha: '',
      },
      token: null,
    }
  },
  methods: {
    submitForm(e){
      e.preventDefault()
      if(this.validacao()){
        const dadosusuario = {
          senha: this.senha,
          token: this.token
          
        }
        AuthServico.redefinirSenha(dadosusuario)
          .then(res => {
            if(res.status === 200){
              alertar('successchangepassword', '=)', 'Senha alterada com sucesso')
            } 
          })
          .catch(error => {
            alertar('warning', '=/', 'Falha na alteração de senha')
            console.log('error', error)
          })
      }
    },
    validacao(){
      let validado = true
      if(!this.senha || this.senha.trim() === ''){
        validado = false
        this.erros.senha = 'O campo senha é obrigatório.'
      }
      if(this.senha.length < 6){
        validado = false
        this.erros.senha = 'A senha deve ter 6 caracteres no mínimo.'
      }
      if(!this.confirmarsenha || this.confirmarsenha.trim() === ''){
        validado = false
        this.erros.confirmarsenha = 'O campo confirmar senha é obrigatório.'
      }
      if(this.senha !== this.confirmarsenha){
        validado = false
        this.erros.confirmarsenha = 'As senhas não conferem. Digite novamente'
      }
      
      return validado
    },
    selecionarcampo(e){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = ''
      }
    },
  },
  mounted(){
    const { token } = this.$route.params
    this.token = token
  }
}
</script>

<style lang="scss" scoped>
.page{
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}
form{
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  padding: 3em 2em;
  border-radius: 50px;
  background: #f9f9fe;
  box-shadow:  4px 4px 10px #d4d4d8;
}

.field{
  display: flex;
  align-items: center;
  justify-content: center;
}

button[type="submit"]{
  background-color: #1377F2;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: .75em 1em;
  border-radius: 10px;
  font-weight: bold;
}

//css indesejados
.col-12, .col-md-6, .col-sm-6{
  padding: unset;
}
</style>